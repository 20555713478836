import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';


function UNIDialog(props) {

    const handleClose = (potvrdit) => {
        props.setDialogOpen(false);
        props.setPotvrzeno(potvrdit);
    };

    return (
        <div>
            <Dialog
                open={props.dialogOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {props.nadpis}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.popis}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(false)} autoFocus>Ne</Button>
                    <Button onClick={() => handleClose(true)}>Ano</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export function DocEmailDialog(props) {

    return (
        <UNIDialog {...props} popis="Opravdu si přejete odeslat dokumenty na centrálu?" nadpis="Odeslat email" />
    );
}

export function ZKEmailDialog(props) {
    return (
        <UNIDialog {...props} popis={`Opravdu si přejete odeslat zelenou kartu na email ${props.email}?`} nadpis="Odeslat email" />
    );
}

export function SmazatSouborDialog(props) {
    return (
        <UNIDialog {...props} popis="Opravdu si přejete smazat vybraný soubor ze serveru?" nadpis="Smazat dokument" />
    );
}