import { useMsal } from "@azure/msal-react";
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Accordion,TextField, AccordionDetails, AccordionSummary, Alert, Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import { pink } from '@mui/material/colors';
import { XMLParser } from "fast-xml-parser";
import fileDownload from 'js-file-download';
import React, { useEffect, useState } from "react";
import { loginRequest } from "../authConfig";
import PDF from '../pdf_small.png';
import { StahniSoubor, VratIdentifikaci, ZkontrolujOP } from "../services/IdentService";
import IdentifikaceForm from "./IdentifikaceForm";
import IdentifikaceSoubory from "./IdentifikaceSoubory";
import { Images } from "./Images";
import { NedostupnaSluzba, NeocekavanaChyba } from "./StavoveHlasky";
import { UniSwitch } from "./styled.js";
import { styles } from "./styles";

function Identifikace(props) {
  const { instance, accounts } = useMsal();
  let [identIndex, setIdentIndex] = useState(0);
  let [identData, setIdentData] = useState([]);
  const [checked, setChecked] = useState(false);
  let [status, setStatus] = useState(200);
  let [error, setError] = useState();
  let [cisloOPRucne, setCisloOPRucne] = useState("");
  let [platnostOP, setPlatnostOP] = useState("");

  const onKontrolaOPClick = (cisloop) => {
    ZkontrolujOP(cisloop).then(response => {
      console.log(response);
      if (response.status === 200) {
        let xml = response.data;
        console.log(xml);
        const options = {
          ignoreAttributes: false,
          attributeNamePrefix: ""
        };
        const parser = new XMLParser(options);
        const output = parser.parse(xml);
        //console.log(output.doklady_neplatne.odpoved.evidovano);
        setPlatnostOP(output.doklady_neplatne.odpoved.evidovano);
      }
    });
  }

  const nactiIdentifikaci = (accessToken) => {
    VratIdentifikaci(accessToken, props.id).then(ident => {
      // console.log(ident.data);
      setStatus(ident.status);
      if (ident.data && ident.status === 200) setIdentData(ident.data);
      if (ident.data && ident.status > 200) setError(ident.data);
    });
  }

  const stahniSoubor = (accessToken, id, nazev) => {
    StahniSoubor(accessToken, id).then(souborPdf => {
      setStatus(souborPdf.status);
      //console.log(souborPdf);
      if (souborPdf.data && souborPdf.status === 200) fileDownload(souborPdf.data, nazev);
      if (souborPdf.data && souborPdf.status > 200) setError(souborPdf.data);
    });
  }

  const pripravStazeniSouboru = (id, nazev) => {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    try {
      instance.acquireTokenSilent(request).then((response) => {
        stahniSoubor(response.accessToken, id, nazev);
      }).catch(() => {
        instance.acquireTokenPopup(request).then((response) => {
          stahniSoubor(response.accessToken, id, nazev);
        });
      });
    } catch (e) {
      console.log(e);
    }
  }

  const handleChange = (event) => {
    setChecked(event.target.checked);
  }

  const handleComboChange = (event) => {
    setIdentIndex(event.target.value);
    setCisloOPRucne("");
    setPlatnostOP("");
  }

  useEffect(() => {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    instance.acquireTokenSilent(request).then((response) => {
      nactiIdentifikaci(response.accessToken);
    }).catch((e) => {
      instance.acquireTokenPopup(request).then((response) => {
        nactiIdentifikaci(response.accessToken);
      });
    });
  }, [instance, accounts]);

  function zobrazSoubory(data) {
    //console.log(data);
    let pdfka = [];
    let obrazky = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].nazev.split('.').pop().toLowerCase() === 'pdf') {
        pdfka.push(data[i]);
      } else {
        obrazky.push(data[i]);
      }
    }

    return (
      <div>
        {pdfka.map((pdf) => (
          <Box key={pdf.nazev} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <img src={PDF} alt="pdf" />
            <Typography>{pdf.nazev}</Typography>
            <Button component="label" variant="outlined" startIcon={<FileDownloadOutlinedIcon sx={{ color: '#dd9234' }} />} sx={{ borderColor: '#dd9234', color: '#dd9234', mr: 1, mb: 1 }} onClick={() => pripravStazeniSouboru(pdf.id, pdf.nazev)}>Stáhnout</Button>
          </Box>
        ))}
        <Images files={obrazky} mazani={false} />
      </div>
    );
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography sx={styles.accorPopis}>Identifikace</Typography>
        {
          (identData.length > 0 && identData.filter(ident => ident.propadlaIdent === true).length > 0) || (identData.length === 0) ?
            <Tooltip title="Identifikace">
              <InfoOutlinedIcon sx={{ mr: 1, p: '2px', color: 'white', bgcolor: pink[500], fontSize: '35px', borderRadius: '8px' }} />
            </Tooltip>
            :
            <Tooltip title="Identifikace">
              <InfoOutlinedIcon sx={{ mr: 1, p: '2px', color: 'white', bgcolor: '#8FD14F', fontSize: '35px', borderRadius: '8px' }} />
            </Tooltip>
        }

      </AccordionSummary>
      <AccordionDetails>
        <div>{status === -1 ? <NedostupnaSluzba /> : <p></p>}</div>
        <div>{status > 200 ? <NeocekavanaChyba chyba={error} /> : <p></p>}</div>

        {identData.length > 0 ?
          <Box sx={{ ml: 1 }} >
            <Box sx={{ display: 'flex', flexDirection: 'column' }} >
              <FormControl sx={{ mb: 2, width: { sm: '50%', lg: '25%' } }}>
                <InputLabel id="zastupce-select-label">Zástupce</InputLabel>
                <Select
                  labelId="zastupce-select-label"
                  id="zastupce-select"
                  value={identIndex}
                  label="Zastupce"
                  onChange={handleComboChange}
                >
                  {identData.map((ident, index) =>
                    <MenuItem value={index} key={index}>{ident.zastupce}</MenuItem>
                  )}
                </Select>
              </FormControl>
              {identData[identIndex].cislodokladu !== null && identData[identIndex].cislodokladu !== "" ?
                <Box>
                  <Box sx={{ mb: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                    <Typography><b>Číslo OP:</b> {identData[identIndex].cislodokladu}</Typography>
                    <Button component="label" variant="outlined" startIcon={<AssignmentIndOutlinedIcon sx={{ color: '#dd9234' }} />} sx={{ borderColor: '#dd9234', color: '#dd9234', mr: 1, mb: 1 }} onClick={() => onKontrolaOPClick(identData[identIndex].cislodokladu)}>Ověřit</Button>
                  </Box>
                  {platnostOP === "ano" ?
                    <Alert severity="warning">POZOR!! Průkaz je evidován jako neplatný.</Alert>
                    :
                    <></>
                  }
                  {platnostOP === "ne" ?
                    <Alert severity="success">Průkaz není evidován jako neplatný. V kontrole však nejsou doklady s prošlou lhůtou a doklady skartované.</Alert>
                    :
                    <></>
                  }
                </Box>
                :
                <Box>
                  <Alert severity="warning">Kontakt nemá vyplněné číslo dokladu v adresáři. Zadejte číslo OP ručně a stiskněte ověřit.</Alert>
                  <Box sx={{mt:1, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: "flex-start", md: "center" }, gap: 1 }}>
                  <TextField
                    label="Číslo OP"
                    id="kontrola-op"
                    value={cisloOPRucne}

                    onChange={(event) => {
                      setCisloOPRucne(event.target.value);
                    }}
                  />
                  <Button component="label" variant="outlined" startIcon={<AssignmentIndOutlinedIcon sx={{ color: '#dd9234' }} />} sx={{ borderColor: '#dd9234', color: '#dd9234'}} onClick={() => onKontrolaOPClick(cisloOPRucne)}>Ověřit</Button>
                  </Box>
                  {platnostOP === "ano" ?
                    <Alert severity="warning">POZOR!! Průkaz je evidován jako neplatný.</Alert>
                    :
                    <></>
                  }
                  {platnostOP === "ne" ?
                    <Alert severity="success">Průkaz není evidován jako neplatný. V kontrole však nejsou doklady s prošlou lhůtou a doklady skartované.</Alert>
                    :
                    <></>
                  }                  
                </Box>
              }
              <FormControlLabel control={<UniSwitch color="warning" checked={checked} onChange={handleChange} />} label="Opsat údaje z dokladů" sx={{ mb: 1 }} />
              {/* { checked  ? <IdentifikaceForm smlouva={props.cislols} setIdentData={setIdentData}></IdentifikaceForm> : <IdentifikaceSoubory smlouva={props.cislols} setIdentData={setIdentData}></IdentifikaceSoubory>} */}
              {checked ? <IdentifikaceForm id={props.id} zastupceid={identData[identIndex].zastupceAdresarId} identData={identData} setIdentData={setIdentData}></IdentifikaceForm> : <IdentifikaceSoubory id={props.id} zastupceid={identData[identIndex].zastupceAdresarId} identData={identData} setIdentData={setIdentData}></IdentifikaceSoubory>}
            </Box>


            {identData[identIndex].id > 0 ?
              <Box>
                <Typography sx={{ lineHeight: '24px' }}>
                  <b>Datum identifikace: </b> {new Date(identData[identIndex].datumIdentLong).toLocaleDateString()}
                  {identData[identIndex].propadlaIdent ?
                    <Tooltip title="Kontakt má identifikaci starší 12 měsíců."><ClearIcon sx={{ color: pink[500] }} /></Tooltip>
                    :
                    <Tooltip title="Kontakt má platnou identifikaci."><CheckIcon sx={{ color: '#8FD14F' }} /></Tooltip>
                  }
                </Typography>
                <Typography sx={{ lineHeight: '24px' }}><b>Uloženo: </b> {new Date(identData[identIndex].datumZapisLong).toLocaleString()}</Typography>
                {(identData[identIndex].soubory !== null && identData[identIndex].soubory.length > 0) ?
                  zobrazSoubory(identData[identIndex].soubory)
                  :
                  <div><i>Doklad byl ručně opsán.</i></div>}
              </Box>
              :
              <Typography>Zástupce nebyl dosud identifikován!</Typography>
            }
          </Box>
          :
          <Alert severity="warning">Nepodařilo se načíst údaje o identifikaci. Zkontrolujte, zda máte v UNIKALKu v případě právnické osoby vybraného alespoň jednoho zástupce.</Alert>
        }
      </AccordionDetails>
    </Accordion>
  );
}

export default Identifikace;