import App from './App.js';
import React from 'react';
import ReactDOM from 'react-dom/client';
import "bootstrap/dist/css/bootstrap.min.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

if (process.env.REACT_APP_ENV === "prod") {
    console.log = () => { }
    console.error = () => { }
    console.debug = () => { }
}

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <ReactNotifications />
            <App />
        </MsalProvider>
    </React.StrictMode>
);