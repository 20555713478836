import { Box, Paper, Typography } from "@mui/material";
import {vnitrniBoxStyles} from "./styles";

export const VnitrniBox =(props) => {
    return(
    <Box sx={{ display:"flex", flexDirection: {xs: 'column', sm: 'row'}}}>
      <Paper variant="outlined" sx={vnitrniBoxStyles.sekce}>
        <Box sx={{display:"flex"}}>
          <Typography align="center" sx={vnitrniBoxStyles.nadpis}>{props.nadpis}</Typography> 
          {props.cenaCelkem ?
            <Typography align="center" sx={vnitrniBoxStyles.cena}>{props.cenaCelkem}</Typography> 
            :
            <></>
          }
        </Box>
        <Box>
        {props.children}
          </Box>           
     </Paper>    
    </Box> 
    )
}