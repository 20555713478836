import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Box, Skeleton, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { loginRequest } from "../authConfig";
import { VratSmlouvu } from "../services/SmlService";
import Detail from "./Detail";
import Dokumenty from "./Dokumenty";
import Fotografie from "./Fotografie";
import Identifikace from "./Identifikace";
import { Store } from 'react-notifications-component';
import { useNavigate, useSearchParams } from "react-router-dom";
import Pojisteni from "./Pojisteni/Pojisteni";
import { NedostupnaSluzba, NeocekavanaChyba } from "./StavoveHlasky";


const UniSkeleton = () => {
  return (
  <Box>
    <Skeleton variant="text" width={100}  sx={{mb:2, fontSize: "1rem" }} />
    <Skeleton variant="text" width={250}  sx={{ fontSize: "1rem" }} />
    <Skeleton variant="text" width={300}  sx={{mb:2, fontSize: "1rem" }} />  

    <Skeleton variant="rounded" sx={{borderRadius: "16px",mb:1 }} height={75} />
    <Skeleton variant="rounded" sx={{borderRadius: "16px",mb:1 }} height={75} />
    <Skeleton variant="rounded" sx={{borderRadius: "16px",mb:1 }} height={75} />
    <Skeleton variant="rounded" sx={{borderRadius: "16px",mb:1 }} height={75} />
  {/* <Skeleton variant="rounded" sx={{borderRadius: "16px",mb:1 }} height={75} /> */}
  </Box>
  );
  }

const theme = createTheme({
  components: {

    MuiAccordionSummary: {
      styleOverrides: {
       
        content: {
          justifyContent: 'space-between',
          height: '50px',
          lineHeight: '50px!important',
          alignItems: 'center',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root:{
          borderRadius: "16px!important",
          marginBottom: "10px",
          "&.MuiAccordion-root:before": {
            display: "none"
          },
        },
        
        content: {
          height: '50px',
        }
      }
    },
    MuiAccordionDetails: {
     styleOverrides: {
      root:{
        paddingLeft: 8,
        paddingRight: 8,
      },
      content:{
        
      }
     } 
    }
  },
});

export default function Obchodnipripad() {
  const { instance,accounts } = useMsal();
  //console.log(accounts[0]);
  let { id } = useParams();
  let [sml,setSml] = useState([]);
  let [VIN,setVIN] = useState("");
  let [status,setStatus] = useState(200);
  let [loading,setLoading] = useState(true);
  const [queryParameters] = useSearchParams();
  let navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  //console.log('rerender');

  const request = {
    ...loginRequest,
    account: accounts[0]
  };
  
  const nactiSml=(accessToken)=> {
    VratSmlouvu(accessToken,id).then(smlTmp => {
      //console.log(smlTmp);
      setStatus(smlTmp.status);
      if (smlTmp.data) {

        setSml(smlTmp.data);
        setVIN(smlTmp.data.karos);
        //setRZ(smlTmp.data.spz);
        //setORV(smlTmp.data.tp);
        //setDpojisteniod(smlTmp.data.dpojisteniod);
        if (smlTmp.data.zaplaceno === 1) {
          Store.addNotification({
            //title: "Realizována platba!",
            message: "Na smlouvě "+ smlTmp.data.smlouva +" byla realizována platba.",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000
          }
        });
        }
      }
    });                  
  }
  
  useEffect(() => {

    instance.acquireTokenSilent(request).then((response) => {
      nactiSml(response.accessToken);
    }).catch((e) => {	 
      // console.log("here");
      // instance.loginRedirect().catch(e => {
      //   console.log(e);
      // }); 	
      navigate("/");
    //   instance.acquireTokenPopup(request).then(
    //   (response) => {
    //     console.log(response);
    //     nactiSml(response.accessToken);
    //   },
    //   (problem) => {
    //     console.log(problem);
    //   }
    
    // );
    });
    setLoading(false);

    // },[instance, accounts]);
  },[]);
  

  return (

    status === 200 ?
    <div>  
      <div>{status === -1 ? <NedostupnaSluzba/>:<p></p>}</div>   
      <div>{status > 200 ? <NeocekavanaChyba chyba={sml}/>:<p></p>}</div> 

      {loading ? 
      <UniSkeleton/>
      :
      <div>

      {queryParameters.get("pojisteni") !== "1" ?
      <div>      
        {sml.predavajici > 0 ?
          <Box sx={{display:"flex", alignItems:"center"}}>
            <Typography variant='subtitle1' sx={{mr:1}}>{sml.smlouva}</Typography>
            <Typography variant='caption'>{'('+sml.uzivatel+', '+sml.mesto+')'}</Typography>
          </Box>
        :
          <Typography variant='subtitle1' sx={{mb:2,mt:1}}>{sml.smlouva}</Typography>
        }

        <Typography align="left" variant="h5" sx={{color:"#003883",fontWeight:"bold", overflow:"hidden",whiteSpace: "nowrap",textOverflow: "ellipsis"}}>{sml.odb}</Typography>
        <Typography align="left" variant="h6" sx={{mb:2,fontWeight:"bold",overflow:"hidden",whiteSpace: "nowrap",textOverflow: "ellipsis"}}>{sml.nazev}</Typography>
      </div>
      :
      <></>
      }
      <ThemeProvider theme={theme}>
      {/* {queryParameters.get("pojisteni") !== "1" ? */}
      <div> 
        <Detail sml={sml} VIN={VIN} />
        <Identifikace id={id} idnajemce={sml.gidnajemce} idzastupce={sml.gidzastupce1naj} />
        <Fotografie id={id}/>
        <Dokumenty id={id} smlouva={sml.smlouva} />

        {sml.pojid>0 ?
        <Pojisteni sml={sml} VIN={VIN} setVIN={setVIN}/>    
        // <Pojisteni sml={sml} VIN={VIN} setVIN={setVIN}/>    
        :
        <></>       
        }
      </div>  
      {/* : */}
      {/* } */}
      </ThemeProvider>    
      </div>
    


      }
    </div>
    :
  <div>
    <div>{status === -1 ? <NedostupnaSluzba/>:<p></p>}</div>
    <div>{status > 200 ? <NeocekavanaChyba chyba={sml}/>:<p></p>}</div> 
  </div>
  );
}