import { useMsal } from "@azure/msal-react";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, TextField, Typography } from '@mui/material';
import { pink } from '@mui/material/colors';
import TableContainer from '@mui/material/TableContainer';
import React, { memo, useEffect, useState } from "react";
import { loginRequest, sendMail } from "../../authConfig";
import { OdesliMail } from "../../services/MailService";
import { SjednejPojisteni, VratPojisteni, VratZelenouKartuBase64 } from '../../services/PojService';
import EmailDokumenty from '../EmailDokumenty';
import PojDialog from '../PojDialog';
import { NedostupnaSluzba, NeocekavanaChyba } from "../StavoveHlasky";
import UniCircularProgressBar from "../UniCircularProgressBar";
import { VnitrniBox } from "../VnitrniBox";
import DoplnujiciUdaje from './DoplnujiciUdaje';
import KeStazeni from "./KeStazeni";
import Shrnuti from './Shrnuti';
import {StahniSoubor} from '../../services/PojService';
import { styles } from '../styles';

const Pojisteni = memo(function Pojisteni({ sml, VIN, setVIN }) {
  
  const { instance, accounts } = useMsal();
  let [statusSjednani, setStatusSjednani] = useState(0);
  let [error, setError] = useState("");
  let [loading, setLoading] = useState(true);
  let [progress, setProgress] = useState(false);
  let [kontrolaVIN, setKontrolaVIN] = useState(false);
  let [datumPojisteniOd, setDatumPojisteniOd] = useState(new Date(sml.dpojisteniod));
  let [pojDialogOpen, setPojDialogOpen] = useState(false);
  let [sjednat, setSjednat] = useState(false);
  let [odeslatMailCentrala, setOdeslatMailCentrala] = useState(false);
  let [poj, setPoj] = useState();
  
  let vychoziEmail = "";
  if (process.env.REACT_APP_ENV==="prod") {
    vychoziEmail = sml.odbemail0;   
  } else {
    vychoziEmail = "krahulec@unileasing.cz";
  }

  let [email, setEmail] = useState(vychoziEmail);   
  let [zelenaKarta, setZelenaKarta] = useState("");

  async function vratToken() {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    try {
      let respToken = await instance.acquireTokenSilent(request);
      return respToken.accessToken;
    } catch (e) {
      let respToken = await instance.acquireTokenPopup(request);
      return respToken.accessToken;
    }
  }

  async function vratTokenMail() {
    const request = {
      ...sendMail,
      account: accounts[0]
    };

    try {
      let respToken = await instance.acquireTokenSilent(request);
      return respToken.accessToken;
    } catch (e) {
      let respToken = await instance.acquireTokenPopup(request);
      return respToken.accessToken;
    }
  }


  const handleSjednat = () => {
    setPojDialogOpen(true);
  }

  useEffect(() => {
    if (poj) {
      setLoading(false);
    }
  }, [poj]);


  const sjednejPoj = (accessToken) => {
    let dnes = new Date();
    let dSjednani = new Date(datumPojisteniOd);
    dnes.setHours(0, 0, 0, 0);

    if (dSjednani > dnes) {
      dSjednani.setHours(0, 2, 0, 0);
    } else {
      dSjednani = new Date();
    }
    let data = { "dpojisteniod": dSjednani, "pojkod": poj.poj };
    let json = JSON.stringify(data);

    SjednejPojisteni(accessToken, sml.id, json).then(response => {
      if (response.status > 200) setError(response.data);
      if (response.status === 200) {

        setPoj(response.data);
        if (response.data.sjednano) {
          StahniSoubor(accessToken, "ZELENA_KARTA", sml.id, response.data.prints.zelenaKarta,true).then(response => {
            if (response.data) {
              setZelenaKarta(response.data);
              setOdeslatMailCentrala(true);
            }
          });
        }
      }
      setStatusSjednani(response.status);
      setProgress(false);
    });
  }

  const sjednatPojisteni = () => {
    setProgress(true);
    vratToken().then((token) => {
      sjednejPoj(token);
    });
  }

  useEffect(() => {
    if (sjednat) {
      sjednatPojisteni();
    }
    setSjednat(false);
  }, [sjednat]);

  useEffect(() => {
    if (odeslatMailCentrala) {
      odeslatEmailCentrala();
    }
    setOdeslatMailCentrala(false);
  }, [odeslatMailCentrala]);

  const nactiPojisteni = (accessToken) => {
    VratPojisteni(accessToken, sml.id).then(pojTmp => {
      setStatusSjednani(pojTmp.status);
      if (pojTmp.data) {
        // console.log(pojTmp.data);
        setPoj(pojTmp.data);
      }
    });
  }

  useEffect(() => {
    vratToken().then((token) => {
      nactiPojisteni(token);
    });
  }, []);

  const odeslatEmailCentrala = () => {
    let currentDate = new Date();
    let datum = currentDate.getDate() + '.' + (currentDate.getMonth() + 1) + '.' + currentDate.getFullYear() + ' ' + ('0' + currentDate.getHours()).slice(-2) + ':' + ('0' + currentDate.getMinutes()).slice(-2) + ':' + ('0' + currentDate.getSeconds()).slice(-2);

    const toRecipients = (process.env.REACT_APP_ENV==="prod") ? 
    [  
      {
        emailAddress: { address: "logger@unileasing.cz" }
      },
      {
        emailAddress: { address: "valentova@unileasing.cz" }
      },
      {
        emailAddress: { address: "lnedoma@unileasing.cz" }
      },
      {
        emailAddress: { address: "lnovy@unileasing.cz" }
      }
    ] 
    :
    [
      {
      emailAddress: { address: "krahulec@unileasing.cz" }
      }
    ];
     
    const Zprava = {
      message: {
        subject: 'Nová pojistná smlouva č. ' + poj.cislopojsmlouvy,
        body: {
          contentType: 'HTML',
          content:
            '<b><span style="font-size:15.0pt;color: #003883;">Nová pojistná smlouva č. ' + poj.cislopojsmlouvy + '</span></b><br/>'
            + '<span style="font-size:15.0pt"></span><br/>'
            + 'Datum generování: ' + datum + ' <br/>'
            + 'Pro pobočku: ' + sml.mesto + ' <br/>'
            + 'Obchodník: ' + sml.uzivatel + '<br/><br/>'
            + '<div style="color:#003883">Na smlouvě ' + sml.smlouva + ' byla sjednána nová pojistná smlouva č. ' + poj.cislopojsmlouvy + ' (' + poj.pojistovna + ').<br/>'
            + 'Klient: ' + sml.odb + '<br>'
            + 'Datum počátku pojištění: ' + poj.dpojisteniod + '<br>'
            + 'Předmět: ' + sml.nazev + '<br/><br/><br/>'
            + '<hr size="1" width="100%" noshade="" style="color:#F39800" align="left"/>'
            + '<p style="margin: 0px; padding: 0px;">'
            + '<em>Veškeré informace jsou generované výhradně pro interní účely UNILEASING a.s. a nelze je přeposílat jiným subjektům.<br>Generováno aplikací WEBUNIKALK '+process.env.REACT_APP_VERSION+', uživatel: ' + sml.uzivatel + '</em></p>'
        },
        ...{ toRecipients },
        attachments: [
          {
            '@odata.type': '#microsoft.graph.fileAttachment',
            name: 'zelena_karta.pdf',
            contentType: 'application/pdf',
            contentBytes: zelenaKarta
          }
        ]
      },
      saveToSentItems: 'true'
    };

    vratTokenMail().then((token) => {
      OdesliMail(token, email, Zprava).then(response => {
        //setStatus(response.status);
      });
    });
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel3a-content"
        id="panel3a-header"
      >
        <Typography sx={styles.accorPopis}>Pojištění</Typography>
        {loading ?
          <></>
          :
          <div>
            {poj.sjednano ?
              <DescriptionOutlinedIcon sx={{ mr: 1, p: '2px', color: 'white', bgcolor: '#8FD14F', fontSize: '35px', borderRadius: '8px' }} />
              :
              <DescriptionOutlinedIcon sx={{ mr: 1, p: '2px', color: 'white', bgcolor: pink[500], fontSize: '35px', borderRadius: '8px' }} />
            }
          </div>
        }

      </AccordionSummary>
      <AccordionDetails>
        {loading ?
          <></>
          :
          <div>
            {poj.sjednano ?
              <></>
              :
              <DoplnujiciUdaje VIN={VIN} setVIN={setVIN} id={sml.id} kontrolaVIN={kontrolaVIN} setKontrolaVIN={setKontrolaVIN} zmenaVIN={poj.zmenaVIN} />
            }
            <VnitrniBox nadpis={"Shrnutí"}>
              <TableContainer component={Box} sx={{ width: { xs: "100%", md: "80%" }, margin: "auto" }}>
                {/* <TabShrnuti /> */}
                <Shrnuti poj={poj}/>


                {poj.sjednano ?
                  <></>
                  :
                  <div>
                    {!poj.zmenaVIN && !kontrolaVIN ?
                      <Box sx={{ mt: 2, display: "flex", gap: 1, alignItems: { xs: "flex-start", md: "center" }, flexDirection: { xs: "column", md: "row" } }}>
                        <Button disabled component="label" variant="outlined" startIcon={<HandshakeOutlinedIcon sx={{ color: '#dd9234' }} />} sx={{ borderColor: '#dd9234', color: '#dd9234' }}>SJEDNAT POJIŠTĚNÍ</Button>
                        {progress ? <UniCircularProgressBar /> : <></>}
                        {VIN === "" ?
                          <></> :

                          <Alert severity="info">Před sjednáním pojištění proveďte kontrolu VIN</Alert>
                        }
                      </Box>
                      :
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {VIN === "" ?
                          <Button disabled component="label" variant="outlined" startIcon={<HandshakeOutlinedIcon sx={{ color: '#dd9234' }} />} sx={{ mt: 2, mb: 2, borderColor: '#dd9234', color: '#dd9234' }}>SJEDNAT POJIŠTĚNÍ</Button>
                          :
                          <Button onClick={handleSjednat} component="label" variant="outlined" startIcon={<HandshakeOutlinedIcon sx={{ color: '#dd9234' }} />} sx={{ mt: 2, mb: 2, borderColor: '#dd9234', color: '#dd9234' }}>SJEDNAT POJIŠTĚNÍ</Button>
                        }
                        {progress ? <UniCircularProgressBar /> : <></>}
                      </Box>
                    }
                  </div>
                }

                <PojDialog datumPojisteniOd={datumPojisteniOd} setDatumPojisteniOd={setDatumPojisteniOd} pojDialogOpen={pojDialogOpen} setPojDialogOpen={setPojDialogOpen} setSjednat={setSjednat}></PojDialog>
                {poj.sjednano ? <Alert sx={{ mt: 2 }} severity="success">Pojištění sjednáno k {poj.dpojisteniod}</Alert> : <></>}
                <div>{statusSjednani === -1 ? <NedostupnaSluzba /> : <p></p>}</div>
                <div>{statusSjednani > 200 ? <NeocekavanaChyba chyba={error} /> : <p></p>}</div>
                {poj.sjednano ?
                  <Box sx={{ mt: 2 }}>
                    {poj.state === 'ERR_DOCS' ?
                      <Alert severity="error">{poj.errors}</Alert>
                      :
                      <div>
                        <Typography sx={{ mb: 1 }}><b>Dokumenty ke stažení</b></Typography>

                        <KeStazeni smlouva={sml.smlouva} dokumentUrl={poj.prints.zelenaKarta} dokumentTyp={"ZELENA_KARTA"} id={sml.id} />
                        <KeStazeni smlouva={sml.smlouva} dokumentUrl={poj.prints.ipz} dokumentTyp={"IPZ"} id={sml.id} />
                        <KeStazeni smlouva={sml.smlouva} dokumentUrl={poj.prints.vpp} dokumentTyp={"VPP"} id={sml.id} />
                        <KeStazeni smlouva={sml.smlouva} dokumentUrl={poj.prints.ipid} dokumentTyp={"IPID"} id={sml.id} />
                        <KeStazeni smlouva={sml.smlouva} dokumentUrl={poj.prints.pi} dokumentTyp={"PI"} id={sml.id} />

                        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: { xs: "flex-start", md: "center" }, gap: 2, mt: 4 }}>
                          <TextField
                            label={"Email"}
                            id="outlined-controlled"
                            value={email}
                            onChange={(event) => {
                              setEmail(event.target.value);
                            }}
                          />
                          <EmailDokumenty poj={poj} email={email} sml={sml} VIN={VIN} />
                          {/* <Button component="label" variant="outlined" startIcon={<EmailOutlinedIcon sx={{color:'#dd9234'}} />} sx={{borderColor:'#dd9234',color:'#dd9234', mr:1 }}>Odeslat dokumenty na email</Button>                 */}
                        </Box>
                      </div>
                    }
                  </Box>
                  :
                  <></>}
              </TableContainer>

            </VnitrniBox>
          </div>
        }
      </AccordionDetails>
    </Accordion>
  );
});

export default Pojisteni;


