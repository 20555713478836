export const styles = {
  accorPopis: {
    fontWeight: 'bold',
  },
  odkaz: {
    color: '#dd9234',
  },
  stavTextEx: {
    lineHeight: '25px',
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#8FD14F',
    borderRadius: '8px',
    pl: 1,
    pr: 1,
  },  
  stavText: {
    lineHeight: '35px',
    color: 'white',
    backgroundColor: '#8FD14F',
    borderRadius: '8px',
    pl: 1,
    pr: 1,
    fontWeight: 'bold',
  },
  nadpis: {
    width: '100px',
    backgroundColor: '#3C486B',
    color: 'white',
    p: "1px",
    borderRadius: '16px',
    mt: "-28px",
    mb: 1
  },
  sekce: {
    flex: "50%",
    p: 2,
    ml: 1,
    mr: 1,
    mt: 1,
    mb: 2,
    borderRadius: '16px',
    borderColor: '#3C486B',
  },
  uniokbadge: {
    "& .MuiBadge-badge": {
      color: 'white',
      backgroundColor: '#088443',
    }
  },
  uniproblembadge: {
    "& .MuiBadge-badge": {
      color: 'white',
      backgroundColor: '#a52b71',
    }
  }
};

export const detailStyles = {
  stavText: {
    lineHeight: '25px',
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#F29400',
    borderRadius: '8px',
    pl: 1,
    pr: 1,
  },
};

export const docStyles = {
  nadpis:{
    width: '200px',
    backgroundColor: '#3C486B',
    color: 'white',
    p:"1px",
    borderRadius: '16px',
    mt:"-65px",
    mb:1,
    position: "absolute"
  },
};

export const nastaveniStyles = {
  nadpis:{
    width: '200px',
    backgroundColor: '#dd9234',
    color: 'white',
    p:"1px",
    borderRadius: '16px',
    mt:"-28px",
    mb:1
  },
  sekce:{
    flex:"50%",
    backgroundColor:'#F0F0F0',
    p:2,
    m:1,
    mb:2,
    borderRadius: '16px',
    borderColor: '#dd9234',
  }
};

export const vnitrniBoxStyles = {
  nadpis:{
    width: '200px',
    backgroundColor: '#3C486B',
    color: 'white',
    p:"1px",
    borderRadius: '16px',
    mt:"-28px",
    mb:1
  },
  sekce:{
    flex:"50%",
    p:2,
    m:1,
    mb:2,
    borderRadius: '16px',
    borderColor: '#3C486B',
  }
};

export const OPStyles = {
  unitextfield: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
    },
  }
};