import { useMsal } from "@azure/msal-react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Skeleton, Typography } from '@mui/material';
import { pink } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useState } from "react";
import { isMobile } from 'react-device-detect';
import { loginRequest } from "../authConfig";
import { NahrajFotky, SmazFotku, StahniFotky } from "../services/FileService";
import Fotak2 from "./Fotak";
import { Images } from "./Images";
import { NedostupnaSluzba, NeocekavanaChyba } from "./StavoveHlasky";
import { UniBadge } from "./styled";
import UniCircularProgressBar from "./UniCircularProgressBar";
import { UniUploadButton } from "./UniUploadButton";
import {styles} from "./styles";

export default function Fotografie(props) { 
    const { instance,accounts } = useMsal();
    let [fotos,setFotos] = useState([]);
    let [statusDownloadImage,setStatusDownloadImage] = useState(200);
    let [statusUploadImage,setStatusUploadImage] = useState(0);
    let [statusDeleteImage,setStatusDeleteImage] = useState(0);
    let [error,setError] = useState();
    let [uploadingImages,setUploadingImages] = useState(false);
    let [loading,setLoading] = useState(true);

    const request = {
        ...loginRequest,
        account: accounts[0]
      };
      
    const handleUploadedImages = (files) => {
        setUploadingImages(true);

        instance.acquireTokenSilent(request).then((response) => {
            NahrajFotky(response.accessToken,props.id,files).then(fotos => {
              setStatusUploadImage(fotos.status);              
              if (fotos.data && fotos.status === 200) setFotos(fotos.data);
              if (fotos.data && fotos.status > 200) setError(fotos.data); 
              setUploadingImages(false);  
          }); 
          }).catch((e) => {	  	
            instance.acquireTokenPopup(request).then((response) => {
                NahrajFotky(response.accessToken,props.id,files).then(fotos => {
                  setStatusUploadImage(fotos.status);              
                  if (fotos.data && fotos.status === 200) setFotos(fotos.data);
                  if (fotos.data && fotos.status > 200) setError(fotos.data); 
                  setUploadingImages(false);                    
                }); 
            });
          });
      }

    const handleDeleteImage = (soubor) => {
        instance.acquireTokenSilent(request).then((response) => {
            SmazFotku(response.accessToken,props.id,soubor).then(fotos => {
              setStatusDeleteImage(fotos.status);
              if (fotos.data && fotos.status === 200) setFotos(fotos.data);
              if (fotos.data && fotos.status > 200) setError(fotos.data); 
          }); 
          }).catch((e) => {	  	
            instance.acquireTokenPopup(request).then((response) => {
                SmazFotku(response.accessToken,props.id,soubor).then(fotos => {
                  setStatusDeleteImage(fotos.status);
                  if (fotos.data) setFotos(fotos.data);
                }); 
            });
          });
          setStatusUploadImage(0); 
      }      

      async function vratToken() {
        const request = {
          ...loginRequest,
          account: accounts[0]
        };
    
        try {
          let respToken = await instance.acquireTokenSilent(request);
          return respToken.accessToken;
        } catch (e) {
          let respToken = await instance.acquireTokenPopup(request);
          return respToken.accessToken;
        }
      }   

      useEffect(() => {
        //console.log("useEffect: "+loading);
        setLoading(true);

        vratToken().then((token) => {
          StahniFotky(token,props.id).then(fotos => {
            //console.log(fotos.data);
            setStatusDownloadImage(fotos.status);
            if (fotos.data && fotos.status === 200) setFotos(fotos.data);
            if (fotos.data && fotos.status > 200) setError(fotos.data); 
            setLoading(false);
          }); 
        });
      },[]);

return (
<Accordion>
<AccordionSummary
  expandIcon={<ExpandMoreIcon />}
  aria-controls="panel3a-content"
  id="panel3a-header"
>
  <Typography sx={styles.accorPopis}>Fotografie</Typography>
  {loading ? 
  <Skeleton variant="rounded" width={35} height={35} />
:
  <Tooltip title="Fotografie">
  {fotos.length > 2 ?
    <UniBadge sx={styles.uniokbadge} badgeContent={fotos.length}>
      <PhotoCameraOutlinedIcon sx={{mr:1,p:'2px',color:'white',bgcolor:'#8FD14F',fontSize:'35px',borderRadius:'8px'}} />   
    </UniBadge>
      :
    <UniBadge sx={styles.uniproblembadge} badgeContent={fotos.length} showZero>
      <PhotoCameraOutlinedIcon sx={{mr:1,p:'2px',color:'white',bgcolor:pink[500],fontSize:'35px',borderRadius:'8px'}} />
    </UniBadge>
  }          
  </Tooltip>
}
</AccordionSummary>
<AccordionDetails> 
    <div>
      <div>{statusUploadImage === 200 ? <Alert severity="success">Fotografie byly úspěšně nahrány na server!</Alert>:<p></p>}</div>
      <div>{statusDownloadImage === -1 || statusUploadImage === -1 || statusDeleteImage === -1 ? <NedostupnaSluzba/>:<p></p>}</div>
      <div>{statusDownloadImage > 200 || statusUploadImage > 200 || statusDeleteImage > 200 ? <NeocekavanaChyba chyba={error} />:<p></p>}</div>

        <Box sx={{display:"flex",alignItems:"center",mb:1}}>
          {isMobile ?
          <Fotak2 typ={-1} handleUploadedFiles={handleUploadedImages}/>:<></>
          }
          <UniUploadButton typ={-1} pdf={false} handleUploadedFiles={handleUploadedImages}/>          
          {uploadingImages ?<UniCircularProgressBar /> :<></>} 
        </Box>
      {fotos.length > 0  ?          
        <Images files={fotos} handleDeleteFile={handleDeleteImage} mazani={true} />
        :
        <div><i>Nejsou nahrány žádné fotografie.</i></div>}
      </div>
</AccordionDetails>
</Accordion>
);
}