
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React, { useState } from "react";

export default function Shrnuti({poj}) {
    console.log(poj);
    let [openLimit, setOpenLimit] = useState(true);
    let [openSpol, setOpenSpol] = useState(true);
    let [openDopln, setOpenDopln] = useState(true);

    const SLOUPEC = "1px";
    const ODSAZENI = "1px";

    return (
        <Table aria-label="poj-table">
            <TableBody>
                <TableRow key={'poj'}>
                    <TableCell width={SLOUPEC} sx={{ p: 0 }}>

                    </TableCell>
                    <TableCell align="left">Pojišťovna</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="right">{poj.pojistovna}</TableCell>
                </TableRow>
                <TableRow key={'pov'}>
                    <TableCell width={SLOUPEC} sx={{ p: 0 }}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenLimit(!openLimit)}
                        >
                            {openLimit ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell align="left">Povinné ručení</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="right">{poj.zpcelkem !== undefined ? poj.zpcelkem.toLocaleString() : "?"} Kč</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                        <Collapse in={openLimit} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Table sx={{ [`& .${tableCellClasses.root}`]: { borderBottom: "none" } }} size="small" aria-label="pov-limit">
                                    <TableBody>
                                        <TableRow key={"pov-limit"}>
                                            <TableCell width={ODSAZENI}></TableCell>
                                            <TableCell align='left'>
                                                Limit: {poj.povlimit}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
                <TableRow key={'hav'}>
                    <TableCell width={SLOUPEC} sx={{ p: 0 }}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenSpol(!openSpol)}
                        >
                            {openSpol ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell align="left">Havarijní pojištění</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="right">{poj.hpcelkem !== undefined ? poj.hpcelkem.toLocaleString() : "?"} Kč</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                        <Collapse in={openSpol} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Table sx={{ [`& .${tableCellClasses.root}`]: { borderBottom: "none" } }} size="small" aria-label="pov-limit">
                                    <TableBody>
                                        <TableRow key={"hav-spol"}>
                                            <TableCell width={ODSAZENI}></TableCell>
                                            <TableCell align='left'>
                                                Spoluúčast: {poj.havspol}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
                <TableRow key={'dopln'}>
                    <TableCell width={SLOUPEC} sx={{ p: 0 }}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenDopln(!openDopln)}
                        >
                            {openDopln ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell align="left">Doplňkové pojištění</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="right">{poj.doplnkoveCelkem !== undefined ? poj.doplnkoveCelkem.toLocaleString() : "?"} Kč</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                        <Collapse in={openDopln} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Table sx={{ [`& .${tableCellClasses.root}`]: { borderBottom: "none" } }} size="small" aria-label="pov-limit">
                                    <TableBody>
                                        {poj.skloCelkem > 0 ?
                                            <TableRow key={"sklo"}>
                                                <TableCell width={ODSAZENI}></TableCell>
                                                <TableCell >
                                                    Čelní sklo ({poj.skloText})
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: "nowrap" }} align="right">{poj.skloCelkem.toLocaleString()} Kč</TableCell>
                                            </TableRow>
                                            :
                                            <></>
                                        }
                                        {poj.asistenceText !== "" ?
                                            <TableRow key={"asistence"}>
                                                <TableCell width={ODSAZENI}></TableCell>
                                                <TableCell >
                                                    {poj.asistenceText}
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: "nowrap" }} align="right">{poj.asistenceCelkem !== undefined ? poj.asistenceCelkem.toLocaleString() : "?"} Kč</TableCell>
                                            </TableRow>
                                            :
                                            <></>
                                        }
                                        {poj.nahradniCelkem > 0 ?
                                            <TableRow key={"nahradni"}>
                                                <TableCell width={ODSAZENI}></TableCell>
                                                <TableCell >
                                                    Náhradní vozidlo ({poj.nahradniText})
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: "nowrap" }} align="right">{poj.nahradniCelkem.toLocaleString()} Kč</TableCell>
                                            </TableRow>
                                            :
                                            <></>
                                        }
                                        {poj.zavazadlaCelkem > 0 ?
                                            <TableRow key={"zavazadla"}>
                                                <TableCell width={ODSAZENI}></TableCell>
                                                <TableCell >
                                                    Pojištění zavazadel ({poj.zavazadlaText})
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: "nowrap" }} align="right">{poj.zavazadlaCelkem.toLocaleString()} Kč</TableCell>
                                            </TableRow>
                                            :
                                            <></>
                                        }
                                        {poj.urazCelkem > 0 ?
                                            <TableRow key={"uraz"}>
                                                <TableCell width={ODSAZENI}></TableCell>
                                                <TableCell >
                                                    Pojištění úrazu ({poj.urazText})
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: "nowrap" }} align="right">{poj.urazCelkem.toLocaleString()} Kč</TableCell>
                                            </TableRow>
                                            :
                                            <></>
                                        }
                                        {poj.zverCelkem > 0 ?
                                            <TableRow key={"zver"}>
                                                <TableCell width={ODSAZENI}></TableCell>
                                                <TableCell >
                                                    Zvěř ({poj.zverText})
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: "nowrap" }} align="right">{poj.zverCelkem.toLocaleString()} Kč</TableCell>
                                            </TableRow>
                                            :
                                            <></>
                                        }
                                        {poj.gapCelkem > 0 ?
                                            <TableRow key={"gap"}>
                                                <TableCell width={ODSAZENI}></TableCell>
                                                <TableCell >
                                                    GAP ({poj.gapText})
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: "nowrap" }} align="right">{poj.gapCelkem.toLocaleString()} Kč</TableCell>
                                            </TableRow>
                                            :
                                            <></>
                                        }
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
                <TableRow key={'celkem'}>
                    <TableCell align="left"></TableCell>
                    <TableCell sx={{ fontSize: "16px", fontWeight: "bold" }} align="left">Celkem</TableCell>
                    <TableCell sx={{ fontSize: "16px", fontWeight: "bold" }} align="right">{poj.castkaCelkem !== undefined ? poj.castkaCelkem.toLocaleString() : "?"} Kč</TableCell>
                </TableRow>

            </TableBody>
        </Table>
    );



}