import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Box, Button, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import fileDownload from 'js-file-download';
import { React, useState } from "react";
import { isMobile } from 'react-device-detect';
import PDF from '../../pdf_small.png';
import { StahniSoubor } from '../../services/PojService';
import { NedostupnaSluzba, NeocekavanaChyba } from "../StavoveHlasky";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

export default function KeStazeni(props) {
    const { instance, accounts } = useMsal();
    let [statusDownload, setStatusDownload] = useState(0);

    let popisek = "";
    let nazev = "";
    switch (props.dokumentTyp) {
        case "ZELENA_KARTA":
            popisek = "Zelená karta";
            nazev = "zelena_karta_"+props.smlouva+".pdf";
            break;
        case "IPID":
            popisek = "Informační dokument o pojistném produktu";
            nazev = "info_pojisteni_"+props.smlouva+".pdf";
            break;
        case "IPZ":
            popisek = "Informace o škodní historii";
            nazev = "info_škodní_historie_"+props.smlouva+".pdf";
            break;
        case "VPP":
            popisek = "Všeobecné podmínky";
            nazev = "poj_vseobecne_podminky_"+props.smlouva+".pdf";
            break;
        case "PI":
            popisek = "Předsmluvní informace";
            nazev = "predsmluvni_informace_"+props.smlouva+".pdf";
            break;
    }

    async function vratToken() {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        try {
            let respToken = await instance.acquireTokenSilent(request);
            return respToken.accessToken;
        } catch (e) {
            let respToken = await instance.acquireTokenPopup(request);
            return respToken.accessToken;
        }
    }

    const stahniDokument = () => {
        // const response = await fetch(props.dokumentUrl);
        // const blob = await response.blob();
        
        // const link = document.createElement('a');
        // const url = URL.createObjectURL(blob);
    
        // link.href = url;
        // link.setAttribute('download', nazev || 'soubor'); // Nastavení názvu
        // document.body.appendChild(link);
        // link.click();
    
        // setTimeout(() => {
        //     URL.revokeObjectURL(url); // Uvolnění paměti
        //     link.remove();
        // }, 100);


        // console.log(nazev);
        // if (props.dokumentUrl) {
        //     const link = document.createElement('a');
        //     link.href = props.dokumentUrl;
        //     link.setAttribute('download', nazev);
        //     document.body.appendChild(link);
        //     link.click();
        //     link.remove();
        //     return;
        // }

        vratToken().then((token) => {
            console.log(props.dokumentUrl);
            StahniSoubor(token, props.dokumentTyp, props.id,props.dokumentUrl).then(response => {
                setStatusDownload(response.status);
                if (response.data && response.status === 200) fileDownload(response.data, nazev);
            });
        });
    }

    return (
        <div>
            {
                props.dokumentUrl ?
                    <div>
                        <Box key={props.dokumentTyp} sx={{ display: "flex", alignItems: "center", gap: 2, mb: 1, ml: 2 }}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 0.7, width: "200px" }}>
                                <img src={PDF} alt="pdf" />
                                <Typography>{popisek}</Typography>
                            </Box>
                            {isMobile ?
                                <div>
                                    <IconButton sx={{ p: 0, mr: 1 }} aria-label="download" onClick={() => stahniDokument()}>
                                        <FileDownloadOutlinedIcon sx={{ color: '#dd9234' }} />
                                    </IconButton>
                                </div>
                                :
                                <Button component="label" variant="outlined" startIcon={<FileDownloadOutlinedIcon sx={{ color: '#dd9234' }} />} sx={{ borderColor: '#dd9234', color: '#dd9234' }} onClick={() => stahniDokument()}>Stáhnout</Button>
                            }
                        </Box>
                        <div>{statusDownload === -1 ? <NedostupnaSluzba /> : <></>}</div>
                        <div>{statusDownload > 200 ? <NeocekavanaChyba chyba={"Soubor se nepodařilo stáhnout. Kontaktujte IT oddělení!"} /> : <></>}</div>
                    </div>
                    :
                    <></>
            }
        </div>
    );
}