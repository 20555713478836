import { alpha, styled } from '@mui/material/styles';
import {Badge} from '@mui/material';
import Switch from '@mui/material/Switch';

export const UniBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 8,
      top: 2,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));

  export const UniSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#dd9234',
      '&:hover': {
        backgroundColor: alpha('#dd9234', theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#dd9234',
    },
  }));